import React from 'react';
import logo from './logo.svg';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <div className="box">
          ELVI SHU
        </div>
        <p className="text-mini">
          Full Stack Developer
        </p>
      </header>
      <p>
        Hello, I'm <span className="text-highlight">Elvi Shu</span>.
      </p>
    </div>
  );
}

export default App;
